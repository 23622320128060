<template>
    <div :class="'CourseHome course-'+context">
        <div v-if="courseid && course" :id="'pagetop-' + courseid" class="pagetop">
            <div :id="'pagelead-' + courseid" class="pagelead">
                <AppBanner />
            </div>
            <div v-if="course && course.title" class="firstblock fullwrapper" :id="'lead-' + course._id">
                <v-container class="pt-6 contentwrapper courselead"  >
                    <ContentBlock :record="lead" :options="{preview:true, noload: true}" />
                    <v-btn v-if="!isEnrolled" class="float-right courseenrollbtn" color="primary" large :to="'/users/enroll/'+courseid">{{ loc('Join This Course') }}</v-btn>
                    <v-btn v-else-if="!completed" class="float-right courseenrollbtn" color="primary" large :to="'/lessons/'+courseid+'/next'">{{ loc('Continue Course') }}</v-btn>
                </v-container>
            </div>
        </div>
        <div v-if="course && course._id" id="pagecontent" class="pagecontent">
            <div style="position:relative" v-if="!preview && users.currentUser && users.currentUser.isEditor">
                <v-btn color="secondary" fab absolute small top right :to="'/admin/courses/'+courseid" >
                    <v-icon>settings</v-icon>
                </v-btn>
            </div>  
            <div class="coursecontent fullwrapper coursesummarywrapper" :id="'summary-' + course._id">
                <v-container class="pt-10 contentwrapper coursesummary"  >
                    <ContentBlock :record="{body:course.description2}" :options="{preview:true, noload: true}" />
                </v-container>
            </div>     
            <div class="fullwrapper coursestats">
                <v-container class="pt-6 contentwrapper coursestatscontainer px-md-14" >
                    <CourseProgress :courseRecord="course" :options="{ classid: classId || false }" />
                </v-container>
            </div>
            <div v-if="classRecord && classRecord.instructor && classRecord.instructor === users.currentUser._id " class="fullwrapper instructorblock">
                <v-container class="pt-6 contentwrapper instructorblockcontainer " >
                    <ClassMetaBlock :classRecord="classRecord" :courseRecord="course" />
                    <!-- <v-card class="mx-auto mb-12 instructorcard"  >
                        <v-card-title ><span class="font-weight-bold">{{classRecord.name}}</span> - {{ loc('You are the instructor for this class.') }}</v-card-title>
                        
                    <v-card-actions><v-btn color="primary" :to="'/classes/enrollment/' + classRecord._id" class="ml-6">{{ loc("View Class Members") }}</v-btn></v-card-actions>
                    </v-card> -->
                </v-container>
            </div>            
            <div v-if="panel === 'welcome'" class="fullwrapper coursewelcome">
                <v-container class="pt-6 pb-8 contentwrapper">
                    <h2>{{ loc('Thank you for signing up to take') }} {{ course.title }}</h2> 
                    <p v-if="classRecord && classRecord.startdate">{{ loc('You have signed up to take a class which begins on')}} {{ classRecord.startdate }}</p>
                    <ContentBlockSimple record="template-signup-confirmation" :options="{}" />
                    <div v-if="instructor">
                        <p>{{ loc('Sincerely') }}</p>
                        <p>{{instructor.name}}</p>
                        <p>{{instructor.email}}</p>
                    </div>
                    <div><v-btn color="primary" :to="'/Course/'+courseid+'/'+classId">{{ loc('Continue') }}</v-btn></div>
                </v-container>
            </div>       
            <div v-else class="lessonsfull fullwrapper">
                <v-container class="pt-6 contentwrapper lessonscontainer"  >
                    <v-row>
                        <v-col class="lessonlistingparent" cols="auto">
                            <LessonsListing :course="course" :options="{isEditor: isEditor, isEnrolled: isEnrolled, classId: classId}" />
                        </v-col>
                        <v-col v-if="!isEnrolled && lessonsidebar" class="courselessonblock mt-12" cols="auto" >
                            <ContentBlockSimple :record="lessonsidebar" :options="{}" />
                        </v-col>
                        <v-col v-else-if="classRecord && classRecord.description2" class="courselessonblock mt-12" cols="12" md="4">
                             <ContentBlock :record="{_id:context+'-'+'class-description2', body: classRecord.description2}" :options="{preview:true, noload: true}" />
                        </v-col>
                        <v-col v-else-if="classRecord && classRecord.regcode && !classRecord.selfpaced" class="courselessonblock mt-12" cols="12" md="4">
                             {{ loc('This is a Private Class.') }}
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div class="firstblock fullwrapper" :id="'coursebottom-' + course._id">
                <v-container class="pt-6 contentwrapper coursebottom"  >
                    <v-row  class='pa-2'>
                        <v-col>
                            <v-btn v-if="!isEnrolled" class="float-right courseenrollbtn" color="primary" large :to="'/users/enroll/'+courseid">{{ loc('Join This Course') }}</v-btn>
                            <v-btn v-else-if="!completed" class="float-right courseenrollbtn" color="primary" large :to="'/lessons/'+courseid+'/next'">{{ loc('Continue Course') }}</v-btn>
                        </v-col>
                    </v-row>

                    <ContentBlockSimple :record="coursebottom" :options="{}" />
                </v-container>
            </div>
            <div  v-if="isEditor && !isEditing" >
                <v-container class='EditorActionBar'>
                    <v-row  class='pa-2'>
                        <v-btn @click="handleAddLesson" color="primary">{{ loc('Add A Lesson') }}</v-btn>
                    </v-row>
                    <v-row  class='pa-2'>
                        <v-btn @click="handleCourseProps" color="primary">{{ loc('Edit Course Properties') }}</v-btn>
                    </v-row>
                <v-row  class='pa-2'>
                    <v-btn @click="preview = !preview" color="info" >{{ loc('Preview') }}</v-btn>
                </v-row>                    
                </v-container>
            </div>
        </div>
        <div v-if="isLoading" id="isLoading" class="isLoading">
            <div id="pagelead-loading" class="pagelead">
                <AppBanner />
            </div>            
            <v-container  v-html="loc('Loading...')">
            </v-container>
        </div>
        <v-container class='d-none'>
            <div class='row'>
                <pre>
                    courseid: {{ courseid }}
                    enrolled {{ isEnrolled }} : {{enrolled.length}} 
                    editing {{ isEditing }}
                    isAdding {{ isAdding }}
                    isLoading {{ isLoading }}
                    preview {{ preview }}
                    classId {{ classId }}
                    isInstructor {{ isInstructor }}
                </pre>
            </div>
            <div class='row'>
                <h3>Course Record</h3>
                <pre>{{ course }}</pre>
            </div>
            <div class='row'>
                <h3>Class Record</h3>
                <pre>{{ classRecord }}</pre>
            </div>
            <div class='row'>
                <h3>Enrollment</h3>
                <pre>{{enrolled}}</pre>
            </div>
            <div class='row'>
                <h3>Instructor</h3>
                <pre>{{ this.users && this.users.currentUser && this.users.currentUser.instructor }}</pre>
            </div>
         
        </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentBlockSimple from './Content_Block_Simple';
import ContentBlock from './Content_Block';
import LessonsListing from './Lessons_Listing';
import CourseProgress from './Course_Progress';
import ClassMetaBlock from './Class_Meta.vue';
import AppBanner from './App_Banner';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _filter from 'lodash/filter';
// import siteconfig from '../config/site.js';

var userretries = 0;

export default {
    name: 'Course',
    metaInfo () {
        let meta = {};
        let info = this.pageInfo;
        let siteconfig = this.siteconfig;
        meta.title = info.title;
        if(siteconfig && siteconfig.sitetitle) meta.titleTemplate = siteconfig.sitetitle;
        if(info.style) meta.style = [{ cssText: info.style }];
        if(info.description) meta.meta = [{vmid: 'description', name: 'description', content: info.description}];
        console.log('meta', info, meta);
        return meta;
    },
    data () {
        return {
            initial: true,
            courseid: false,
            course: false,
            context: false,
            record: false,
            blocks: false,
            lessonrecords: false,
            classId: false,
            classRecord: false,
            userenrollment: false,
            enrolled: false,
            isEnrolled: false,
            isEditing: false,
            isAdding: false,
            isLoading: false,
            panel: false,
            preview: false
        }
    },
    components: { 
       ContentBlock, ContentBlockSimple, AppBanner, LessonsListing, CourseProgress, ClassMetaBlock
    },
    computed: {
        ...mapState(['siteconfig', 'users', 'courses', 'content', 'lessons', 'enrollment', 'localize']),
        isEditor() {
            return !this.preview && this.users.currentUser && this.users.currentUser.isEditor;
        },
        pageInfo() {
            return this.course  || {}
        },
        lead() {
            // if(!this.course) return false;
            return {
                body: "<h1>" + this.course.title + "</h1><div class='coursedescription'>" + this.course.description + "</div>",
                video: this.course.video,
                videoplacement: "first"
            }
        },
        lessonsidebar() {
            return false;
            // let id = this.context + "-publicsidebar";
            // let record = this.content.list[id] || {
            //     _id: id,
            //     context: this.context,
            //     timestamp: Date.now(),
            //     type: "block",
            //     title: this.courseid + " Lesson Sidebar",
            //     body: "Sidebar content"
            // };
            // console.log("Course.lessonsidebar", record);
            // return record;
        },
        coursebottom() {
           let id = this.context + "-coursebottom";
            let record = this.content.list[id] || {
                _id: id,
                context: this.context,
                timestamp: Date.now(),
                type: "block",
                title: this.courseid + " Course Footer",
                body: "Course Foot"
            };
            console.log("Course.coursebottom", record);
            return record;
        },
        instructor() {
            return false;            
        },
        completed() {
            if(!this.users || !this.userenrollment) return false;
            let list = _filter(this.userenrollment, (o) => {
                if( o.course !==  this.courseid ) return false;
                return !!o.completed;
            });
            return(list && list.length);
        },
        isInstructor() {
            return !!(
                this.courseId && 
                this.users && 
                this.users.currentUser && 
                this.users.currentUser.instructor && 
                this.users.currentUser.instructor[this.classId]
            );
            // if(
            //     !this.users || !this.users.currentUser || !this.users.currentUser.certifications || 
            //     !this.courseId ||  !this.courseRecord
            // ) return false;
            // return (this.courseRecord.instructor === this.users.currentUser._id);
        },
        theuser() {
            return this.users && this.users.currentUser
        }
    },
    methods: {
        loadCourse: function(courseid){
            console.log('loadCourse', this.isLoading, courseid)
            if(this.isLoading) return;
            if(!courseid) courseid = this.courseid; 
            this.course = {}
            this.isLoading = true;
            this.initial = false;
            let context = this.context || "course_" + courseid;
            this.$store.dispatch('courses/get', {course: courseid, context: context})
                .then((result) => {
                    this.isLoading = false;
                    
                    result && (result.videoplacement = "left");
                    this.course = result;
                    // console.log("loaded course", result);
                    if(this.courseid){ 
                        this.$store.dispatch("lessons/preload", this.courseid)
                        .catch((e) => {
                            if(e) console.log(e);    
                        });
                    }
                    // if(this.navitems && !this.navitems.courses) {
                    //     let coursenav = [...navgroup];
                    //     let userlang = this.users.lang || "en";
                    //     for(let i in result){
                    //         if(result[i].lang === userlang) coursenav.push({title: result[i].title, goto:"/Course/"+result[i]._id})
                    //     }
                    //     this.$store.dispatch('setNav',{key: "courses", 'items': coursenav}, { root: true });
                    // } else {
                    //     console.log("Courses navitems already set", this.navitems);
                    // }                    
                })
        },
        loadContent: function(courseid){
            let context = this.context || "course_" + courseid;
            this.initial = false;
            this.$store.dispatch('content/loadPage', {context: context})
                .then((result) => {
                    // console.log(result);
                    // this.isLoading = false;
                    if(!result || result._id === "pagenotfound"){
                        console.log("Content for this course was not found.", result);
                        this.blocks = {
                        };
                        return;
                    }
                    this.blocks = result;
                    // console.log("loaded content", result);
                })
                .catch((e) => {
                    console.log("Course.loadContent error", e);
                })
        },
        getClass: function(classid){
            classid = classid || this.classId;
            if(classid){
                this.$store.dispatch("classes/get", { _id: classid })
                    .then((result) => {
                        if(result && result._id) this.classRecord = result;
                    })
            } else {
                this.$store.dispatch("classes/getList")
                    .then((result) => {
                        console.log(result);
                    })
            }
        },

        handleAddLesson: function () {
            this.$router.push({path:"/admin/lessons/"+this.courseid+"/new", query:{after: 'course'}})

            // let timestamp = new Date();
            // let newkey = this.course + timestamp.valueOf().toString(36);
            // let record = {
            //     _id: newkey,
            //     course: this.courseid,
            //     body: '',
            //     timestamp: timestamp.toISOString(),
            //     order: this.blocks.length + 1
            // };
            // if(record[this.courseid] && record[this.courseid].lang) record[newkey].lang = record[this.course].lang;
            // this.$set(this.lessonrecords, newkey, record);
            // this.isAdding = true;
            // this.isEditing = newkey;
        },
        handleCourseProps: function (evt, opts){
            this.$router.push({path: '/admin/courses/'+this.courseid, query:{after: 'course'}})

        },
        handleEditorActions: function (evt, opts){
            console.log("handleEditorActions", evt, opts);
            if(!evt || !evt.action) return;

            switch(evt.action){
                case 'toggleedit':
                    this.isEditing = evt.editing;
                    // if(this.isAdding) this.isAdding = false;
                    break;
                case 'remove':
                    this.isEditing = false;
                    this.loadPage();
            }
        },
        togglePreview: function() {
            this.preview = !this.preview;
        },
        init: function () {
            console.log('Course init', this.$route.params, this.initial)
            let changed = this.courseid !== this.$route.params.courseid;
            this.courseid = this.$route.params.courseid || 'l3dfsooiif';
            this.context = "course_" + this.courseid;
            this.classId = this.$route.params.classid;
            this.panel = this.$route.params.pathMatch || false;
            if(this.initial || changed) this.loadCourse();
            if(this.initial) this.loadContent();

            if(this.courses && this.courses.list && this.courses.list[this.courseid] && this.users && this.users.lang && this.courses.list[this.courseid].lang !== this.users.lang){
                let maincourse = this.courses.list[this.courseid].main;
                if(this.siteconfig && this.siteconfig.maincourses && this.siteconfig.maincourses[maincourse]){
                    // for(let maincourse in this.siteconfig.maincourses){
                        for(let courseid in this.siteconfig.maincourses[maincourse].courses){
                            if(this.siteconfig.maincourses[maincourse].courses[courseid] === this.users.lang){
                                let newcid = courseid + ''
                                let querystringclassid = '';
                                if(this.classId && this.siteconfig.maincourses[maincourse].selfpaced[this.classId]){
                                    for(let classid in this.siteconfig.maincourses[maincourse].selfpaced){
                                        if(this.siteconfig.maincourses[maincourse].selfpaced[classid] === courseid) querystringclassid = '/'+classid;
                                    }
                                }
                                // console.log("Course rerouting to", newcid)
                                this.$router.push('/Course/' + newcid + querystringclassid);
                                return;
                            }
                        }
                    // }
                }
            }      

            if(this.users && this.users.currentUser){
                this.userenrollment = this.enrollment && this.enrollment.list && this.enrollment.list[this.users.currentUser._id];
                if(this.classId && this.userenrollment && this.userenrollment[this.classId]){
                    this.isEnrolled = true;
                    this.enrolled = _filter(this.userenrollment, {course: this.courseid});
                } else {
                    this.enrolled = _filter(this.userenrollment, {course: this.courseid});
                    if(this.enrolled && this.enrolled.length){
                        console.log("Course Init checking class", this.enrolled);
                        this.isEnrolled = true;
                        if(this.enrolled.length === 1) this.classId = this.enrolled[0].classid;
                    } else {
                        this.isEnrolled = false;
                    }
                }
            } else {
                this.isEnrolled = false;
                if(userretries < 3){ 
                    userretries++; 
                    return setTimeout(this.init, 300); 
                }
            }
            if(this.isEnrolled && this.classId){
                if(this.classes && this.classes.list && this.classes.list[this.classId]){ 
                    this.classRecord = this.classes.list[this.classId]; 
                } else {
                    this.getClass();
                }
            } else {
                let oldclass = this.classId;
                this.classRecord = false;
                this.classId = false;
                if(this.$route.params.classid && this.$route.params.classid !== oldclass) this.$router.replace({params: {classid: false}})
            }
      
            console.log('Course init complete', this.courseid, this.context, this.classId, this.panel, this.isEnrolled, this.classes, this.initial);
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },             
    },
    mounted () {
        this.$nextTick(() => { console.log("mounted nextTick"); this.init(); });

        // this.courseid = this.$route.params.courseid || 'Basics';
        // this.context = "course_" + this.courseid;
        // this.loadCourse();
        // this.loadContent();
        // if(Object.keys(this.courses.list).length < 1){ 
        //     this.loadCourses(); 
        // }
    },
    watch: {
        '$route.params.courseid': {
            handler: function(s){
                console.log("watcher triggered", s);
                // this.courseid = s || 'home';
                // this.loadCourse();
                this.init();
            },
            deep: true,
            immediate: false
        },
        theuser: function(a,b){
            console.log("Course User change", a, b); 
            this.init()
        },
        'users.lang':{
            handler: function(s){
                console.log("Course watcher triggered for language", s, this.$route.params);
                // this.courseid = s || 'home';
                // this.loadCourse();
                this.init();
            },
            deep: true,
            immediate: false
        },


    }
};
</script>

