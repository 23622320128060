<template>
    <v-container>
        <div v-if="!users || !users.currentUser">
            <UserRegister :after="'/users/enroll/' + (this.courseId || '')" />
        </div>
        <div v-else-if="!courseId">
            <v-row>
                <h2>{{ loc("Which course would you like to join?") }}</h2>
            </v-row>
            <v-row>
                <v-select key="courseselect" v-model="courseId" :items="availableCourses" @change="changeCourse"></v-select>
            </v-row>
        </div>  
        <div v-else-if="result">
            <v-row>
                <v-col id="register-intro" style="min-height:130px;" class='feature mt-10' >
                    <ContentBlockSimple  :record="content_enrollintro"  />
                </v-col>
            </v-row> 
            <v-row class='mb-8 '>
                <h2>{{loc("Enrolling in")}} {{ courseRecord.title || courseId }}</h2> 
            </v-row>            
            <v-row>
                <pre>{{result}}</pre>
            </v-row>
        </div> 
        <div v-else>
            <v-row>
                <v-col id="register-intro" style="min-height:130px;" class='feature mt-10' >
                    <ContentBlockSimple  :record="content_enrollintro"  />
                </v-col>
            </v-row>             
            <v-row class='mb-8'>
                <h2>{{loc("Enrolling in")}} {{ courseRecord.title || courseId }}</h2> 
            </v-row>
            <v-row>
                <h2>{{ loc("How would you like to study?") }}</h2>
            </v-row>
            <v-row>
                <v-radio-group v-model="howtostudy">
                    <v-radio key="selfstudy" :label="loc('I want to study on my own, at my own pace.')" value="selfpaced" ></v-radio>
                    <v-radio key="private" :label="loc('I was invited to join a private group and received a registration code.')" value="regcode" ></v-radio>
                </v-radio-group>
            </v-row>
            <v-row v-if="howtostudy === 'regcode'" class="ml-6">
                <v-text-field key="regcode" v-model="regcode" :label="loc('Registration Code')" style="max-width:400px;" ></v-text-field>
                <v-btn @click="verifyRegCode" color="primary" class="mt-3">{{loc('Verify')}}</v-btn>
            </v-row>

            <div v-if="howtostudy === 'regcode' && availableClasses" class="my-12 pa-6 elevation-2">
                <div v-for="(item, key) in availableClasses" v-bind:key="key"  class='my-4' >
                    <v-row><v-col cols="2"><label>{{ loc('Class Name') }}:</label></v-col><v-col>{{item.name}}</v-col></v-row>
                    <v-row><v-col cols="2" ><label>{{ loc('Instructor') }}:</label></v-col><v-col>{{item.instructorname}}</v-col></v-row>
                    <v-row v-if="item.description && item.description.length"><v-col cols="2" ><label>{{ loc('Description') }}:</label></v-col><v-col v-html="item.description"></v-col></v-row>
                    <v-row><v-col cols="2" ><label>Start:</label></v-col><v-col>{{ formatDate(item.startdate) }}</v-col></v-row>
                    
                    <div v-if="isEnrolled(item._id)">
                    <v-row><v-col cols="2"></v-col><v-col><h3>{{ loc('You are already enrolled in this class') }}</h3></v-col></v-row>
                    <v-row><v-col cols="2"></v-col><v-col><v-btn color="primary" :to="'/lessons/'+item.course+'/next/'+item._id">{{ loc('Continue Course') }}</v-btn></v-col></v-row>
                    </div>
                    <v-row v-else><v-col cols="2"></v-col><v-col><v-btn color="primary" @click="doEnrollment(item._id)">{{ loc('enroll') }}</v-btn></v-col></v-row>

                </div>
            </div>
            <div v-else-if="howtostudy === 'selfpaced'" class="my-12 pa-6 elevation-2">
                <div class='my-4' >
                    <v-row><v-col cols="2"><label>{{ loc('Class Name') }}:</label></v-col><v-col>{{selfPacedRecord.name}}</v-col></v-row>
                    <v-row v-if="selfPacedRecord.description && selfPacedRecord.description.length"><v-col cols="2" ><label>{{ loc('Description') }}:</label></v-col><v-col v-html="selfPacedRecord.description"></v-col></v-row>

                    <div v-if="isEnrolled(selfPacedRecord._id)">
                        <v-row ><v-col cols="2"></v-col><v-col><h3>{{ loc('You are already enrolled in this class') }}</h3></v-col></v-row>
                        <v-row><v-col cols="2"></v-col><v-col><v-btn color="primary" :to="'/lessons/'+selfPacedRecord.course+'/next/'+selfPacedRecord._id">{{ loc('Continue Course') }}</v-btn></v-col></v-row>
                    </div>
                    <v-row v-else><v-col cols="2"></v-col><v-col><v-btn color="primary" @click="doEnrollment(selfPacedRecord._id)">{{ loc('enroll') }}</v-btn></v-col></v-row>
                </div>
            </div>
            <div v-else-if="classNotFound" class="my-12 pa-6 elevation-2">
                <v-row><v-col cols="auto">{{ loc('I am sorry, I cannot find that class') }}</v-col></v-row>
            </div>
        </div>
        <div style="display:none;">
            <v-row class="mt-16">Course: {{ courseId }}</v-row>
            <v-row>How tp study: {{ howtostudy }}</v-row>
            <v-row>Class: {{ classId }}</v-row>
            <v-row>Reg code: {{ regcode }}</v-row>
        </div>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import UserRegister from './User_Register';
import ContentBlockSimple from './Content_Block_Simple';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import { filterDate } from '../lib/filter.js';

export default {
    name: "Enroll",
    props: {
        userId: { type: String },
        record: { type: [Object, Boolean], default: false },
        subpanel: { type: [String, Boolean], default: false }
    },
    data () {
        return {
            content_enrollintro: {type: "snippet", _id: 'enrollintro' },
            courseId: this.subpanel,
            howtostudy: false,
            selfpaced: false,
            classId: false,
            classNotFound: false,
            classRecord: false,
            availableClasses: false,
            regcode: '',
            result: false
        }
    },
    components: { ContentBlockSimple, UserRegister },
    computed: {
        ...mapState(['siteconfig', 'users', 'localize', 'content', 'courses', 'classes', 'enrollment']),
        availableCourses: function(){
            if(!this.courses || !this.courses) return [];
            let out = [];
            let lang = this.users.lang || "en";
            let list = _filter(this.courses.list, {lang: lang});
            list = _sortBy(list, ['title']);
            for(let i in list){
                if(list[i].status !== "Inactive") out.push({ value: list[i]._id, text: list[i].title })
            }
            return out;
        },
        courseRecord: function(){
            console.log('users.enroll.courseRecord', this.courseId, this.courses.list)
            return (this.courseId && this.courses.list && this.courses.list[this.courseId]) || {};  
        },
        selfPacedRecord: function(){
            if(!this.courseId) return {};
            let list = _filter(this.classes.list, {course: this.courseId, selfpaced: true})
            console.log('selfPacedRecord',list);
            return (list && list.length && list.length === 1 && list[0]) || {}
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },     
        changeCourse: function() {
            let newpath = "/users/enroll/"+this.courseId+"/";
            this.$router.push(newpath)
        },
        verifyRegCode: function() {

            if(!this.regcode) return;
            this.classNotFound = false;

            let list = _filter(this.classes.list, {regcode: this.regcode});
            if(list && list.length){
                this.availableClasses = list;
            } else {
                this.$store.dispatch("classes/getList", {course: this.courseId, regcode: this.regcode, nocache: true})
                    .then((result) => {
                        if(result && Object.keys(result).length){
                            this.availableClasses = result;
                            console.log("I can work with this", result);
                        } else {
                            console.log("verifyRegCode problem", result);
                            this.classNotFound = true;
                        }
                    })
            }
        },
        doEnrollment: function(classid) {
            this.classId = classid;

            if(this.courseId && this.classId && (this.availableClasses || this.howtostudy === 'selfpaced')){
                this.$store.dispatch("enrollment/enroll", {courseid: this.courseId, classid: this.classId})
                    .then((result) => {
                        console.log("doEnrollment result", result);
                        this.result = (result && result.value) || false;
                        this.$router.push("/Course/"+this.courseId);
                    });
            }
        },
        // handleHowtostudy: function() {
        //     this.classId = (this.howtostudy === 'selfpaced') ? this.selfPacedRecord._id : false;
        // },
        isEnrolled: function(classid){
            console.log("UserEnroll.isEnrolled 1", enrolled, this.courseId, this.users.currentUser, classid);
            if(!this.courseId || !this.users.currentUser || !classid ) return false;
            let enrolled = this.enrollment && this.enrollment.list && this.enrollment.list[this.users.currentUser._id] && this.enrollment.list[this.users.currentUser._id][classid];
            console.log("UserEnroll.isEnrolled", enrolled);
            return enrolled;
        },
        formatDate: function(str){ 
            return filterDate(str, 'YYYY-MM-DD') 
        }
    }
    
}
</script>

<style scoped>
label {
    font-weight: bold;
}
</style>