import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import users from './store_users';
import content from './store_content';
import courses from './store_courses';
import classes from './store_classes';
import comments from './store_comments';
import enrollment from './store_enrollment';
import integrationVcrm from './store_integration_vcrm';
import lessons from './store_lessons';
import files from './store_files';
import localize from './store_localize';
import reports from './store_reports';
import surveys from './store_surveys';

import defaultconfig from '../config/site';

Vue.use(Vuex);

var timers = {};
const messageLife = 4500;

const store = new Vuex.Store({
    modules: { users, content, localize, courses, lessons, classes, enrollment, comments, files, integrationVcrm, surveys, reports },
    state: { 
        statusMessages: {},
        navitems: {
            left: [
                { title:"Home", icon: "home", url: "/", key: "home"},
                // { title: "About", icon: "place", url: "/About", key: "about" },
                { title: "How It Works", icon: "place", url: "/HowItWorks", key: 'howitworks' },
                // { title: "About Our Courses", icon: "place", url: "/Courses", key: 'courses' },
                // { title: "Resources", icon: "place", url: "/Resources", key: "resources" },
            ],
            top: [
                { title: "Training", url: "/", key: "training" },
                { title: "Resources", url: "https://disciplenations.org/resources/", key: "resources" },
                { title: "Blog", url: "https://disciplenations.org/blog/", key: "blog" },
                { title: "About", url: "https://disciplenations.org/about/", key: "about" },
                { title: "Get Involved", url: "https://disciplenations.org/contact/", key: "getinvolved" },
                { title: "DONATE", url: "https://dnationsprod.wpengine.com/donate/", key: "donate", cssclass: "donatebutton" },
            ]

                // { title: "Admin", icon: "admin_panel_settings", goto: "/admin/index", group: 'admin' },
            // ],
            // courses: [
            //     {title: "Courses", icon: "class", goto: "/Courses", group: "courses", key: "courses"}
            // ]

        },
        initDone: true,
        siteconfig: defaultconfig
    },
    mutations: {
        setInitDone (state, val) {
            state.initDone = true;
        },
        setNav (state,val) {
            if(!val) return;
            if(val.key && val.items !== undefined){
                if(!val.items){
                    Vue.delete(state.navitems, val.key);
                } else {
                    if(val.newOnly && state.navitems[val.key]) return;
                    Vue.set(state.navitems, val.key, val.items);
                }
            }
        },
        setConfig (state, val) {
            if(!val || typeof val !== "object") return;
            val._timestamp = val._timestamp || Date.now();
            let nopersist = false;
            console.log('setConfig mutate', val);
            if(val.nopersist){
                nopersist = true;
                delete val.nopersist;
            }
            if(!state.siteconfig) state.siteconfig = {};
            for(let k in val){
                if(typeof val[k] === 'object' && val[k] !== null){
                    if(!state.siteconfig[k]) Vue.set(state.siteconfig, k, {});
                    for(let f in val[k]){
                        Vue.set(state.siteconfig[k], f, val[k][f]);
                    }
                } else {
                    // state.siteconfig[k] = val[k];
                    Vue.set(state.siteconfig, k, val[k]);
                }
                
            }
            if(!nopersist && state.siteconfig) localStorage.setItem('lms_config', JSON.stringify(state.siteconfig));
        },  
        unsetConfig (state, val) {
            state.siteconfig = false;
        },
        setMessage(state, val){
            if(val && val.key && val.message) state.statusMessages[val.key] =  val;
        },
        unsetMessage(state, val){
            if(val && state.statusMessages[val]){
                Vue.delete(state.statusMessages, val)
            }
        }
    },
    actions: {
        init ({commit, state}){
            let config = localStorage.getItem('lms_config');
            // console.log('localStorage.getItem lms_config', defaultconfig, typeof config, config);
           
            if(config && config != 'undefined'){ 
                config = JSON.parse(config);
                config.nopersist = true;
            } else {
                config = {};
            }
            config = Object.assign({}, defaultconfig, config);
            commit('setConfig', config);
        },
        clearAll ({commit, state}){
            console.log("root clearAll");
            // this isn't working.  It isn't finding the module paths.  Needs {root:true} even though its in root?
            store.dispatch('/users/clearAll');
            store.dispatch('/content/clearAll')
            return Promise.resolve();
        },
        setConfig ({commit, state, rootState}, data) {
            console.log('rootState.users.lang', rootState.users.lang);
            if(data && data.navitems && rootState.users && rootState.users.lang && data.navitems[rootState.users.lang]){
                for(let k in data.navitems[rootState.users.lang]) commit('setNav',{key: k, items: data.navitems[rootState.users.lang][k]});
            }
            commit('setConfig', data);
        },
        setNav ({commit, state}, data){
            console.log('setNav', data);
            commit('setNav', data);
            return Promise.resolve();
        },
        setNavAdmin ({commit, state}) {
            // console.log('store.setNavAdmin')
            if(state.navitems.admin) return;

            let list = [{ title: "Admin", icon: "admin_panel_settings", url: "/admin/index", group: 'admin' }]
            // console.log('store.setNavAdmin', list);
            commit('setNav', {key: 'admin', items: list});
        },        
        setMessage: function({commit, store}, data) {
            console.log("setMessage", data);
            if(data && !data.message) return;
            if(typeof data === "string") data = {message: data};
            const types = ['success', 'info', 'warning', 'error'];
            if(data){ 
                data.style = (data.style && types.indexOf(data.style) > -1) ? data.style : "info";
                data.key = data.key || Date.now().toString(36);
                if(!data.ttl || typeof data.ttl !== 'number') data.ttl = messageLife;
            }
            this.commit('setMessage', data);

            if(timers[data.key]) clearTimeout(timers[data.key]);
            timers[data.key] = setTimeout(() => {
                this.commit('unsetMessage', data.key);
                if( timers[data.key] ) delete timers[data.key];
            }, data.ttl)
        },    
            
    },
    getters: {}
});

export default store;

const preloadmats = (userclasses) => {
    // for(let c in userclasses){
    //     store.dispatch('lessons/preload', userclasses[c].course);
    // }
}
store.dispatch('localize/init');
store.dispatch('init');
store.dispatch('users/whoami')
    .then((result) => {
        if(result){
            if(result.config) store.dispatch('setConfig', result.config);
            if(result.loc) store.dispatch('localize/setLanguage', result.loc);
            if(result.user && result.user.classes){
                let enroll = {};
                enroll[result.user._id] = result.user.classes;
                // store.dispatch('enrollment/set', enroll)
                store.dispatch('enrollment/set', result.user)
                .then(() => {
                    for(let i=0;i<4;i++){
                        setTimeout(() => {preloadmats(result.user.classes)}, 100 * (i+1));
                    }
                });
            }
        }
    });
store.dispatch('courses/getList');
store.dispatch('classes/getList');
store.dispatch('lessons/getList', {materials: true});

if(!timers.whoami)  {
    timers.whoami = setInterval(() => {
        store.dispatch('users/whoami')
            .then((result) => {
                if(result){
                    if(result.config) store.dispatch('setConfig', result.config);
                    if(result.loc) store.dispatch('localize/setLanguage', result.loc);
                    if(result.user && result.user.classes){
                        store.dispatch('enrollment/set', result.user)
                            .then(() => {
                                // for(let c in result.user.classes){
                                //     store.dispatch('lessons/preload', result.user.classes[c].course);
                                // }
                            });
                    }
                }
            })
    }, 20000)
}
