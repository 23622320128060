import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        getReport ({commit, state}, data){
            if(!data || !data.reportid) return Promise.reject("What do you want from me?");

            let reportid = data.reportid;
            delete data.reportid;

            return axios.post("/app_reports/" + reportid, data, axiosconfig)
            .then((response) => {
                let result = response && response.data;
                // commit('setList', result)
                return result;
            })
            .catch((e) => {
                console.log("error getting report data.", e.response)
            })
        }
    },
}