<template>
    <v-row class="contentblock px-1" ref="contentblock">
        <!-- Text -->
        <v-col v-if="isEditing && block" :key="contentid + '-editing'">
            <ContentEditExtended :record="record" v-on:actionhandler="handleEditorActions" :options="options" />
        </v-col>
        <v-col  v-else-if="!isEditing && block && block !='<p></p>'" class="pb-6" :key="contentid">
            <div  v-html="block || ''" :class="truncated ? 'trunccontent' : 'fullcontent'"></div>
            <div class='showmore' v-if="trunc"><span class='showmorelink' @click="truncated = !truncated">{{ truncated ? loc('Show More') : loc('Show Less') }}</span></div>
        </v-col>
        <!-- VIDEO -->
        <v-col v-if="!isEditing && video" :key="video" class="pa-8" :order="record.videoplacement" :cols="(record.videoplacement === 'solo') ? '12' : 'auto'">
            <SharedMediaPlayer :record="record" :options="playeroptions" />
        </v-col>
        <div style="position:relative" v-if="!isEditing && !options.preview && users.currentUser && users.currentUser.isEditor">
            <v-btn color="secondary" fab absolute small bottom right @click="toggleEditing" >
                <v-icon>edit</v-icon>
            </v-btn>
        </div>
    </v-row>    
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "ContentBlock",
    props: {
        record: {
            type: Object
        },
        options: {
            type: Object,
            default: () => { return {}}
            /** 
             * editing || isEditing - bool
             * trunc - bool - truncate text to fit a set height
             * emitsave - bool - whether we should emit on save rather than call the content store save action directly
             * field - string - field name to set with the content on save (description, description1, description2, etc)
             * load - bool - call get from store even if we have the doc already.
             * getprops - object - collection to pass to get (with the _id and type added)
            */
        },
        fields: {
            type: Object,
            default: () => { return {} }
        }
    },
    data () {
        return {
            // block: (this.record && (this.record.body || this.record.description)) || "",
            body: '',
            doc: this.record,
            isEditing: this.options && (this.options.editing || this.options.isEditing),
            editor: false,
            editorcontent: "",
            height: false,
            video: this.record && this.record.video,
            videoplacement: this.record && this.record.videoplacement,
            type: (this.record && this.record.type) || "snip",
            contentid: (this.record && this.record._id) || false,
            contentblock: (this.record && (this.record.body || this.record.description)) || false,
            context: (this.record && this.record.context) || "",
            trunc: this.options.trunc,
            truncated: false
        }
    },
    components: { 
            ContentEditExtended: () => import(/* webpackPrefetch: true */ './Content_Edit_Extended'),
            SharedMediaPlayer: () => import(/* webpackPrefetch: true */ './Shared_Media_Player'),
    },    
    computed: {
        ...mapState(['users', 'content', 'localize']),
        ckey: function(){
            return (this.content && this.content[this.contentid] && this.content[this.contentid].key) || this.contentid;
        },
        truncate: function() {
            console.log('truncating', this.height, this.doc);
            return this.trunc && (this.height > 1300) ;
        },
        playeroptions: function() {
            let has, opts = {}
            if(!this.block || this.block === '<p></p>' || this.record.videoplacement === 'solo'){
                opts.solo = true;
                has = true;
            }
            return opts;
        },
        block: function() {
            let str = (this.body || this.record && (this.record.body || this.record.description)) || ""
            // console.log("ContentBlock block str", str), this.fields
            if(!this.fields || !str){
                return str;
            }
            // console.log("Running substitions", this.fields);
            for(let k in this.fields){
                let reg = '/{{\s+'+k+'\s+}}';
                // console.log("replacing", reg, 'with', this.fields[k])
                let str = str.replace(reg, this.fields[k]);
            }
            return str;
        },
    },
    methods: {
        getContent () {
            let opts =  {_id: this.contentid, type: this.type };
            if(this.options.getprops){ 
                opts = Object.assign({}, this.options.getprops, opts); 
                console.log("ContentBlock getprops", this.options.getprops, opts);
            }
            if(this.options.load || this.options.remote) opts.remote = 'true';
            this.$store.dispatch('content/get', opts)
            .then((result) => {
                // console.log('ContentBlock got a block',result);
                if(result && result.body !== undefined){
                    this.doc = result;
                    this.body = result.body;
                    this.type = result.type;
                    this.contentid = result._id;
                    this.contentblock = result.body;
                    this.context = result.context;
                    this.video = result.video;
                }
                // if(result.body && result.body.length) this.block = result.body;

                
            })
        },
        toggleEditing (setTo) {
            console.log('toggleEditing');
            this.isEditing = (setTo !== undefined) ? setTo : !this.isEditing;
            this.$emit("actionhandler", {action: 'toggleedit', editing: this.isEditing});
        },
        handleEditorActions: function (action, doc, params){
            console.log('handleEditorActions', action);
            switch(action){
                case 'close':
                    this.toggleEditing();
                    break;
                case 'remove':
                    this.handleRemove( doc );
                    this.toggleEditing();
                    break;
                case 'save':
                    this.handleSave(doc, params)
                case 'toggleedit':
                    this.toggleEditing();
                    break;
            }
        },
        handleSave (change, next){
            this.editorcontent = change;
            console.log('change', change);
            this.block = (typeof change === "string") ? change : (this.options.field) ? change[this.options.field] : change.body || change.description;

            let doc = Object.assign({}, this.record, change)
            if(this.options.emitsave){
                this.$emit("actionhandler", {action: 'save' }, doc);
            } else {
                this.$store.dispatch('content/save', doc)
                    .then((result) => {
                        console.log('ContentBlock.handleSave', result);
                        if(result && result.body){ 
                            this.block = result.body;
                            if(this.doc) this.doc.body = result.body;
                        }
                        if(next === "close") this.toggleEditing();
                    })
                    .catch((err) => {
                        console.log("ERROR in handleSave", err);
                    })
            }
        },
        // handleChange (doc){
        //     console.log('changed', doc);
        // },
        handleRemove (doc){
            console.log('handleRemove called in ContentBlock', doc, this.record);
            if(this.options.emitsave){
                this.$emit('actionhandler', {action: 'remove', record: doc})
            } else {
                this.$store.dispatch('content/remove', this.record);
            }
            
        },
        // toggletrunc () {
        //     this.truncclass = (this.truncclass === 'fullcontent') ? 'trunccontent' : 'fullcontent';
        // },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        init: function (opts) {
            if(this.contentblock === false){ 
                if(opts && opts.retries){
                    this.getContent();
                } else {
                    setTimeout(() => { this.init({retries: 1}); }, 200 )
                }
            } else {
                if(this.options && this.options.isEditing) this.toggleEditing(this.options.isEditing)
                if(this.options.trunc){
                    setTimeout(() => {
                        // this.height = this.$parent.$el.offsetHeight; 
                        this.height = this.$refs.contentblock.clientHeight;
                        if(this.height > 580){ 
                            this.truncated = true;
                            this.trunc = true;
                        } else {
                            this.truncated = false;
                            this.trunc = false;
                        }
                        console.log('Content_Block.truncate', this.doc && (this.doc.title || this.doc._id), this.$refs.contentblock.clientHeight);
                    }, 330);
                }
            }
        },
    },
    created () {
        this.init();

        // console.log('loading ContentBlock', this.contentblock, this.record, this.options)
    },
    watch: {
        contentid: function (id) {
            if(this.record && !this.record.description && (this.record.body === false || this.options.load) ) this.getContent()
        }
    }      
}

</script>
