<template>
    <div class="bannercontainer" mobile-breakpoint="960" :style="($vuetify.breakpoint.width < 960) ? 'z-index:0' : 'z-index:20'">
        <v-container v-if="showbanner1 && $vuetify.breakpoint.width >= 960">
            <v-row id="banner1" class="banner1" v-if="showbanner1">
                <v-col cols="12" md="auto"><img :src="loc('_sitelogo')" id='sitelogo' to="/" height="80"/></v-col>
                <v-spacer></v-spacer>
                <v-col class="banner1item" cols="12" md="auto" v-for="(item) in navlist" :key="item.key">
                    <v-btn text :href="item.url" dark :class="item.cssclass" >{{ loc(item.title) }}</v-btn>
                </v-col>            
            </v-row>
        </v-container>
        <v-container v-if="showbanner1 && $vuetify.breakpoint.width < 960">
            <v-row id="banner1" class="banner1 pl-3" >
                <img :src="loc('_sitelogo')" id='sitelogo' @click="goto('/')" height="80"/>
                <v-spacer></v-spacer>
            </v-row>
        </v-container>        
        <v-app-bar 
            
            app flat 
            color="#00b8f5" 
            dark dense 
            class='appBanner' 
            style="position: relative; left: 0" 
            :clipped-left="$vuetify.breakpoint.width >= 960"
            :clipped-right="$vuetify.breakpoint.width >= 960"
        >
            <v-btn class="ma-2 dark bannermenubtn" color="primary" @click="handleNavClick" ><v-icon dark left >menu</v-icon>{{ loc('Menu') }}</v-btn>
            <v-spacer ></v-spacer>
            <v-toolbar-items class="pl-3 hidden-sm-and-down"> 
                    <span v-for="(language, key) in langlist" v-bind:key="key" class='selectlang' @click="switchLanguage(key)">
                        <span class="mr-3">|</span>{{language}}
                    </span> 
            </v-toolbar-items>
            <v-toolbar-items v-if="users && users.currentUser" class="pl-3 hidden-sm-and-down" style="padding-top:0;">
                <v-menu open-on-hover light offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small v-bind="attrs" v-on="on" id="bannerperson"><v-icon>person</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="users && users.currentUser">
                            {{ users.currentUser.firstname || "" }} {{ users.currentUser.lastname || "" }}
                        </v-list-item>                    
                        <v-list-item @click="gotoprofile">
                            {{ loc('Profile') }}
                        </v-list-item>
                        <v-list-item @click="gotoprofile('enrollment')">
                            {{ loc('My Classes') }}
                        </v-list-item>                        
                        <v-list-item @click="logout">
                            {{ loc('Logout') }}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
            <v-toolbar-items v-else-if="!isLoginPath" ><v-btn text small class="hidden-sm-and-down text-uppercase" to="/Login" style="margin-top:-6px;"><v-icon left>login</v-icon>{{ loc('login') }}</v-btn></v-toolbar-items>
        </v-app-bar>
</div>
</template>

<script>

import { mapState } from 'vuex';

// I'm not sure there are any situations in which this gets used, because the
// default set is also in the default config, but at the moment it wasn't worth
// optimizing this out.
const fallbacklanguages = {
                en: "English",
                es: "Español",
                pt: "Português",
                fr: "Français",
                // bn: "বাংলা (Bangla)",
                ar: "العربية",
                de: "Deutsch"
            }

export default {
    props: {
        togglenav: {
            type: Function
        }
    }, 
    data () {
        return {
            showbanner1: true
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'content', 'siteconfig', 'navitems']),
        isLoginPath () {
            console.log('current route', !!(this.$route.name === "login"), this.$route);
            return !!(this.$route.name === "login");
        },
        langlist  () {
            let languages = (this.siteconfig && this.siteconfig.publiclanguages) || fallbacklanguages;
            let list = {};
            for(let k in languages){ 
                if(k !== this.users.lang) list[k] = languages[k];
            }
            return list;
        },
        navlist () {
            return this.navitems.top;
        },
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },        
        gotoprofile (panel) {
            this.$router.push('/users/profile' + ((panel && typeof panel === 'string') ? ('/' + panel) : ''))
        },
        logout () {
            this.$store.dispatch('users/logout')
                .then(() =>{
                    if(this.$route.path !== '/') this.$router.push('/');
                })
        },
        switchLanguage (lang) {
            if(lang === 'de'){
                this.$router.push('/German');
            } else {
                this.$store.dispatch("users/switchLanguage", lang )
                    .then((result) => {
                        console.log('Topbar.switchLanguage', result);
                        this.$vuetify.rtl = !!(lang === 'ar');
                    })
            }
        },
        handleNavClick () {
            this.togglenav()
        }
    }
}
</script>


