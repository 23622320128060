<template>
<v-container>
    <v-row v-if="!users.loggedout" style="padding-top:36px;text-align:center">
         <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <div v-else>
        <v-row style='height: 60px;' >
            <v-card v-if="message || initialmessage" :class="messagetype" class="align-center pa-3 elevate-2 mx-auto" dark>
                <h3 class='px-10'>{{ message }}</h3>
            </v-card>
        </v-row>
        <v-row>
            <v-card class="mx-auto my-12" width="460" max-width="96%" flat>
                <v-card-title >
                    <h3>{{ loc("Login") }}</h3>
                </v-card-title>
                <v-form @submit.prevent="login">
                    <v-card-text>
                        <v-text-field outlined full-width prepend-icon="person" name="username" v-model="username" :label="loc('Email Address')" type="text" autocomplete="username"></v-text-field>
                        <v-text-field 
                            outlined 
                            full-width 
                            prepend-icon="lock" 
                            name="password" 
                            v-model="password" 
                            :label="loc('Password')" 
                            id="password" 
                            autocomplete="current-password" 
                            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showpass = !showpass"
                            :type="showpass ? 'text' : 'password'"                            
                            
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" dark type="submit" class="mr-2">{{ loc('Login') }}</v-btn>
                    </v-card-actions>
                </v-form>
                <v-card-text id="forgot" class="text-right"><router-link to='/passwordreset'>{{ loc('Forgot your Password?') }}</router-link></v-card-text>
            </v-card>
        </v-row>
        <v-row id="signup" class="align-center">
            <div class="mx-auto" style="width:420px">
                {{ loc("Don't have an account?") }}  <v-btn color="secondary" dark to="/signup" class='ml-3'>{{ loc("Sign Up!") }}</v-btn>
            </div>
        </v-row>
    </div>
</v-container>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Login',
        props: {
            after: {
                type: String
            },
            initialmessage: { type: [String, Boolean], default: false },
            initialmessagetype: { type: String, default: "info" }
        },
        data () {
            return {
                username: "",
                password: "",
                showpass: false,
                destination: this.after || "/",
                polling: false,
                message: this.initialmessage,
                messagetype: this.initialmessagetype
            }
        },
        computed: {
            ...mapState(['users', 'localize'])
        },
        methods: {
            loc: function (str) {
                return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
            },
            poll: function () {
                if(this.users.currentUser){
                    this.$router.push(this.destination);
                } else if(!this.users.loggedout){
                    this.$store.dispatch("users/whoami");
                    this.polling = true;
                    setTimeout(this.poll, 1200);
                }
            },
            login: function () {
                this.polling.false;
                let username = this.username;
                let password = this.password;
                let next = "/"; // we'll want to set this dynamically.
                var query = (this.$route.query && this.$route.query.redirect) || this.after;
                // var _this = this;
                console.log('logging in', username, query);
                this.message = false;
                // if (query) query = { path: query };
                // var goNext = (function () { console.log('goNext', _this.$router, query); _this.$router.push(query); });
                this.$store.dispatch("users/login", { username, password })
                    .then((result) => {
                        if(this.users.currentUser){
                            this.message = false;
                            this.$router.push(next);
                        } else if(result && result.status === 401){
                            this.setIncorrect();
                        }
                        console.log('result', result);
                        
                    })
                    .catch(err => console.log('error', err));
                // this.$router.push('/signup');
                
            },
            setIncorrect: function () {
                console.log('setting incorrect');
                this.message = this.loc("Incorrect username or password");
                this.messagetype = "error";
            }
        },
        mounted () {
            if(this.$route.params.initialmessage) this.message = this.$route.params.initialmessage;
            if(this.$route.params.initialmessagetype) this.messagetype = this.$route.params.initialmessagetype;
            this.poll()
        }
    };
    
</script>


