/** 
* This file contains the very basic defaults for the site configuration.  Most or all of these values
* should be replaced within a second or so of the site load fetching current config from the server.
* But having these right in this file helps prevent render blink.
*/

export default {
    sitetitle: 'Coram Deo | %s',
    defaultcoursesmall: '/images/marvin-meyer-SYTO3xs06fU-unsplash-768x512.jpg',
    defaultlang: 'en',
    materialTypes: [ 'Introduction', 'Presentation', 'Article', 'Application', 'Quiz', 'Exam', 'Survey' ],
    publiclanguages: {
                en: "English",
                es: "Español",
                pt: "Português",
                fr: "Français",
                // bn: "বাংলা (Bangla)",
                ar: "العربية",
                de: "Deutsch"
            }
}

