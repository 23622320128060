<template>
    <div>
        <div class="mt-4 edituserprofile" v-if="isEditing && (isSelf || users.currentUser.isEditor)">
            <v-row>
                <v-list dense>
                    <v-list-item justify="space-between" v-for="(item, key) in schema" v-bind:key="key">
                        <v-list-item-content class="profilefield" :style="'width: '+($vuetify.breakpoint.mdAndUp ? '600px' : '100%') ">
                            <v-select v-if="item.type === 'select'" 
                                :items="item.list" 
                                v-model="userRecord[key]" 
                                :name="key" 
                                :label="loc(item.label)"
                            ></v-select>
                            <v-textarea v-else-if="item.type === 'textarea'"
                                :name="key"
                                v-model="userRecord[key]"
                                :label="loc(item.label)"
                                
                            ></v-textarea>
                            <v-text-field v-else
                                :name="key"
                                v-model="userRecord[key]"
                                :label="loc(item.label)"
                            ></v-text-field>
                        </v-list-item-content>
                    </v-list-item>      
                </v-list>  
            </v-row>
            <v-row> 
                <v-btn color="primary" class='mr-3' @click.stop="save">{{ loc('Save') }}</v-btn> 
                <v-btn @click.stop="handleCancel">{{ loc('Cancel') }}</v-btn> 
            </v-row>
            
        </div>
        <v-row class="mt-4 userprofile" v-else>
            <v-list dense>
                <v-list-item v-for="(item, key) in schema" v-bind:key="key">
                    <v-list-item-content class="profilelabel pr-3">{{ loc(item.label) }}:</v-list-item-content>
                    <v-list-item-content class="align-end profilevalue">
                        {{ record[key] }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div v-if="isSelf">
                <v-btn color="primary" @click.stop="isEditing = !isEditing">{{loc('Edit Profile')}}</v-btn>
            </div>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { _size } from "lodash/size";

export default {
    name: "UserProfile",
    props: {
        record: { type: Object, default: () => ({}) },
        userId: { type: String },
    },
    data() {
        return {
            isEditing: false,
            userRecord: { ...this.record },
            schema: {
                firstname: {
                    label: "First Name"
                },
                lastname: {
                    label: "Last Name"
                },
                email: {
                    label: "Email"
                },
                address1: {
                    label: "Address 1"
                },
                address2: {
                    label: "Address 2"
                },
                city: {
                    label: "City"
                },
                state: {
                    label: "State/Province"
                },
                country: {
                    label: "Country",
                    type: "select",
                    list: []
                },
                postal: {
                    label: "Postal Code"
                },
                heard: {
                    label: "How did you hear about us?",
                    // type: "select",
                    list: ["Friend or colleague", "The Disciple Nations Alliance website", "A Disciple Nations Alliance e-mail", "A web promotion or advertisement", "WEA Newsletter", "Other"]
                },
                vocation: {
                    label: "Vocation",
                    // type: "select",
                    list: ["Agriculture", "Arts", "Business, Commerce", "Church, Clergy", "Education", "Entertainment, Sports", "Government", "Healthcare", "Home, Family", "Law", "Media, Communications", "Science, Technology", "Social Services", "Student", "Other"]
                },
                birthDate: {
                    label: "Birthday"
                },
                bio: {
                    label: "Bio",
                    type: "textarea"
                }
            }

        };
    },
    computed: {
        ...mapState(["users", "content", "localize", "config", "siteconfig"]),
        isSelf() {
            return this.userId === this.users.currentUser._id;
        },
    },
    methods: {
        save: function () {
            let rec = {
                _id: this.userId
            };
            for(let k in this.schema){
                rec[k] = this.userRecord[k];
            }
            this.$store.dispatch("users/save", rec)
                .then( (result) => {
                    console.log("returned from save", result);
                    if(result && result._id === this.userId){ 
                        for(let k in result){
                            this.$set(this.userRecord, k, result[k])
                        }
                    }
                    this.isEditing = false;
                })

        },
        handleCancel: function (){
            this.userRecord = {...this.record};
            this.isEditing = false;
        },
        loc: function (str) {
            return (
                (this.localize.languages[this.users.lang] &&
                    this.localize.languages[this.users.lang][str]) ||
                str
            );
        },
        init: function(){
            if((!this.schema.country || !this.schema.country.list || !this.schema.country.list.length) && (this.siteconfig.countries && this.siteconfig.countries['0']) ){
                console.log("adding countries", this.siteconfig.countries)
                this.schema.country.list = Object.values(this.siteconfig.countries)
            } else if(!this.schema.country || !this.schema.country.list || !this.schema.country.list.length){
                console.log("setting timeout for countries")
                setTimeout(this.init, 500)
            }
        }
    },
    mounted () {
        for(let k in this.schema){
            if(this.userRecord[k] === undefined) this.userRecord[k] = '';
        }
        this.init()
    },    
};
</script>