<template>
    <v-navigation-drawer 
        app
        :value="togglenav" 
        ref="drawer"  
        :clipped="$vuetify.breakpoint.mdAndUp"
        :permanent="togglenav" 
        class="navsidebar" 
        :width="width" 
        :right="isRTL"
        mobile-breakpoint="960"
        style="max-height: 100% !important"
    >
        <template v-slot:prepend v-if="$vuetify.breakpoint.width >= 960">
            <div class="navprepend">
                <img :src="loc('_sitelogo')" height="80" />
               
                <v-btn class="dark prependbtn" color="primary"  @click="toggleaction">
                    <v-icon dark left >menu</v-icon>
                    {{ loc('Close') }}
                </v-btn>
            </div>
        </template>
        <v-btn v-if="$vuetify.breakpoint.width < 960" class="ma-2 dark" color="primary" @click="toggleaction">
            <v-icon dark left >menu</v-icon>
            {{ loc('Close') }}
        </v-btn>
        <v-list dense >
            <v-list-item v-if="!users || !users.currentUser" to="/Login" @click="toggleaction">
                <v-list-item-icon>
                    <v-icon>login</v-icon>
                </v-list-item-icon>   
                <v-list-item-content>
                    <v-list-item-title v-text="loc('Login')"></v-list-item-title>
                </v-list-item-content>                             
            </v-list-item>
            <v-list-item v-else-if="$vuetify.breakpoint.width < 960" @click="logout">
                <v-list-item-icon>
                    <v-icon>logout</v-icon>
                </v-list-item-icon>   
                <v-list-item-content>
                    <v-list-item-title v-text="loc('Logout')"></v-list-item-title>
                </v-list-item-content>                             
            </v-list-item>
            <v-list-item v-if="$vuetify.breakpoint.width < 960" to="/users/profile">
                <v-list-item-icon>
                    <v-icon>person</v-icon>
                </v-list-item-icon>   
                <v-list-item-content>
                    <v-list-item-title v-text="loc('User Profile')"></v-list-item-title>
                </v-list-item-content>                             
            </v-list-item>            

            <v-list-item v-for="item in items" :key="item.title" :to="item.url" @click="handleClick">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title v-text="loc(item.title)"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>'class'</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class='pa-3'>{{ loc('Courses') }}</v-list-item-title>
                    <v-list-group v-for="(item, k) in coursenav" v-bind:key="k + '-' + item._id" v-model="courseactive[k]" >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title  @click="init(null, {refresh:1})">
                                    {{ item.title }}
                                </v-list-item-title>
                             </v-list-item-content>
                        </template>
                        <v-list-item  :to="item.url" @click="handleClick">
                            <v-list-item-content>
                                <v-list-item-title class='pl-2'>{{ loc('Overview') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>                        
                        <template  v-for="(subitem) in item.subitems" >
                            <v-list-item  v-if="siteconfig && siteconfig.showeachmaterial && lessons.materials && lessons.materials[subitem.key]"  class='navsubitem' v-bind:key="subitem.key">
                                <v-list-item-content >
                                    <v-list-group v-model="lessonactive[subitem.key]" sub-group>
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title :title="subitem.title" class='navsubitemtitle' >{{ subitem.title }}</v-list-item-title>
                                             </v-list-item-content>
                                        </template>
                                        <div v-if="materialsList && materialsList[subitem.course]">
                                            <v-list-item v-for="(mat, matkey) in materialsList[subitem.course][subitem.key]" :to="subitem.url + '/' + matkey" v-bind:key="mat.key"  @click="handleClick">
                                                <v-list-item-title :title="mat.title" :class="'pl-4 ' + ((mat.viewed && ' materialviewed') || ' materialnotviewed') + ((mat.required && ' materialrequired') || ' materialoptional') ">
                                                    <v-icon v-if="mat.viewed" color="green" small>done</v-icon> {{mat.title}}
                                                </v-list-item-title>
                                            </v-list-item>
                                            
                                        </div>
                                    </v-list-group>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-else :to="subitem.url" v-bind:key="'g-'+subitem.key" @click="handleClick">
                                <v-list-item-content >
                                    <v-list-item-title :title="subitem.title" :class="'pl-6 navsubitem ' + subitem.class" >
                                        <v-list-item-icon>
                                            <v-icon>arrow_drop_down</v-icon>
                                        </v-list-item-icon>                                        
                                        {{ subitem.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list-group>
                </v-list-item-content>
            </v-list-item>
            
            <template v-if="$vuetify.breakpoint.width < 960">
                <v-list-item  v-for="item in navitems.top" :key="item.key" :href="item.url" @click="handleClick">
                    <v-list-item-icon v-if="item.icon">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-text="loc(item.title)"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>               

            <v-list-item v-if="adminnav && adminnav.length === 1" to="/admin/index" @click="handleClick">
                <v-list-item-icon>
                    <v-icon>admin_panel_settings</v-icon>
                </v-list-item-icon>                  
                <v-list-item-content>
                    <v-list-item-title >{{ loc('Admin') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-group 
                v-else-if="adminnav"
                :value="true"
                prepend-icon="admin_panel_settings"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title >{{ loc('Admin') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="item in adminnav" :key="item.title" :to="item.url"  @click="handleClick">
                    <v-list-item-content>
                        <v-list-item-title v-text="loc(item.title)"  class='pl-2'></v-list-item-title>
                    </v-list-item-content>                    
                </v-list-item>

            </v-list-group>

            <v-list-group 
                v-if="$vuetify.breakpoint.smAndDown"
                prepend-icon="language"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title >{{ loc('Languages') }}</v-list-item-title>
                    </v-list-item-content>
                </template>     
                <v-list-item  v-for="(language, key) in langlist" v-bind:key="key">
                    <v-list-item-content class='pl-2'>
                        <v-list-item-title class='selectlang' @click="switchLanguage(key)" >
                            {{language}}
                        </v-list-item-title> 
                    </v-list-item-content>
                </v-list-item > 
           
            </v-list-group>
            <v-list-item v-if="adminnav" to="/util/store" @click="handleClick">
                <v-list-item-icon>
                    <v-icon>schema</v-icon>
                </v-list-item-icon>   
                <v-list-item-content>
                    <v-list-item-title v-text="loc('Browser Data Explorer')"></v-list-item-title>
                </v-list-item-content>                             
            </v-list-item>
        </v-list>
        <div v-if="adminnav ">
            <v-divider></v-divider>
            <div>{{courseid}} / {{lessonid}} / {{classid}}</div>
            <div>{{ $vuetify.breakpoint.width }}</div>
        </div>
    </v-navigation-drawer>
</template>

<script>
    // <!-- @click="groupclick(subitem.url)" -->
import { mapState } from 'vuex';
import _sortBy from 'lodash/sortBy';
import _trim from 'lodash/trim';
import _filter from 'lodash/filter';

let initlock = false;

export default {
    props: {
        toggleaction: {
            type: Function
        },
        togglenav: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            width: 360,
            borderSize: 3,
            courseactive: {},
            lessonactive: {},
            courseid: false,
            lessonid: false,
            classid: false, 
            materialsList: false,
            isInitialized: false

            // moved to the store.
            // ditems: [
            //     { title: this.loc("Home"), icon: "home", url: "/" },
            //     { title: this.loc("About"), icon: "place", url: "/About" },
            //     { title: this.loc("Admin"), icon: "admin_panel_settings", url: "/admin/index" },
            // ],
        };
    },
    computed: {
        ...mapState(['users', 'localize', 'navitems', 'courses', 'lessons', 'enrollment', 'siteconfig']),
        items(){
            // console.log("navitems", this.navitems);
            return this.navitems.left;
            // let list = [];
            // for(let k in this.navitems){
            //     // console.log('nav', k, this.navitems[k]);
            //     if(this.navitems[k]) list.push(...this.navitems[k]);
            // }
            // return list;
        },
        adminnav(){
            return (this.users && this.users.currentUser && this.users.currentUser.isAdmin && this.navitems.admin) || false;
        },
        isRTL(){
            return !!(this.$vuetify.rtl || ( this.users && this.users.lang === 'ar'));
        },
        coursenav(){
            let lang = this.users.lang;
            if(!lang) lang =  "en";
            let list = false;
            let classid = this.classid || this.$route.params.classid;
            let courseid = this.courseid || this.$route.params.courseid;
            let userid = this.users && this.users.currentUser && this.users.currentUser._id;
            if(this.courses && this.courses.list && this.lessons && this.lessons.list){
                list = {};
                // console.log("reticulating course nav", this.courses.list, this.lessons.list);

                let lessonlist = _sortBy(this.lessons.list, ['course', 'sortorder'])
                // list.push({title: "Courses", icon: "class", url: "/Courses", group: "courses", key: "courses"})

                let visited = [];
                let quizzes = [];
                let last = {_id: false, lastview: ''};
                // let classappend = (this.$route.params && this.$route.params.classid) ? ('/' + this.$route.params.classid) : ""
                for(let k in this.courses.list){ // k is courseid
                    let isEnrolled = false;
                    let classappend = (classid && k === courseid) ? ('/' + classid) : '/none';
                    if(this.enrollment && this.enrollment.list){
                        for(let e in this.enrollment.list[userid]){ // e here is a classid
                            if(this.enrollment.list[userid][e].course === k){ 
                                isEnrolled = (this.courses.list[k].status !== 'Inactive' || this.siteconfig.showinactivecourseswhenenrolled);
                                if(this.enrollment.list[userid][e].progress){
                                    for(let lid in this.enrollment.list[userid][e].progress){
                                        visited.push(lid);
                                        if(this.enrollment.list[userid][e].progress[lid].quizdate) quizzes.push(lid);
                                        if(this.enrollment.list[userid][e].progress[lid].lastview > last.lastview){ 
                                            last = this.enrollment.list[userid][e].progress[lid]; 
                                            // console.log("nav: LAST", this.enrollment.list[userid][e].progress[lid].lastview)
                                            last._id = lid;
                                        } else { 
                                            // console.log("nav: this is not the last",this.enrollment.list[userid][e].progress[lid].lastview )
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                    if(this.courses.list[k].lang === lang && (this.courses.list[k].status !== "Inactive" || isEnrolled)){
                        list[k] = {
                            title: this.courses.list[k].title,
                            url: "/Course/" + k + classappend,
                            key: this.courses.list[k]._id,
                            order: this.courses.list[k].order,
                            subitems: []
                        }
                        let sections = false;
                        if(this.courses.list[k].sections){
                            sections = this.courses.list[k].sections.split(',');
                            for(let s in sections) sections[s] = _trim(sections[s]);
                        }

                        // list.push({title: this.courses.list[k].title, url:"/Course/"+this.courses.list[k]._id})
                        if(isEnrolled){
                            for(let i in lessonlist){
                                if(lessonlist[i].course === k){
                                    list[k].subitems.push({
                                        title: lessonlist[i].title,
                                        course: k,
                                        url: "/lessons/" + k + "/" + lessonlist[i]._id + classappend,
                                        sortorder: lessonlist[i].sortorder,
                                        sectionorder: (sections) ? (sections.indexOf(lessonlist[i].sorder) + 1) : 0,
                                        class: "navlesson " + 
                                            ( (visited.indexOf(lessonlist[i]._id) > -1 && (last && last._id !== lessonlist[i]._id) && 'navlessonvisited ') || "" ) + 
                                            ( (quizzes.indexOf(lessonlist[i]._id) > -1 && 'navlessonquizzed ') || "" ) + 
                                            ( (last && last._id === lessonlist[i]._id && "navlessoncurrent ") || "" ),
                                        key: lessonlist[i]._id
                                    })
                                }
                            }
                            if(sections) list[k].subitems = _sortBy(list[k].subitems, ['sectionorder', 'sortorder']);
                        }
                    }
                }
                // console.log('course nav', visited, quizzes, last);
            } else {
                // console.log('cannot add courses to nav yet')
            }
            list = _sortBy(Object.values(list), ['order', 'title'])
            return list;
        },
        langlist: function() {
            return this.siteconfig && this.siteconfig.publiclanguages || [];
        }
    },
    methods: {
        setBorderWidth() {
            const e = this.$refs.drawer.$el.querySelector(
                ".v-navigation-drawer__border"
            );
            e.style.width = "3px";
            e.style.cursor = "ew-resize";
            
        },    
        setResizeEvents() {
            const minSize = this.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const __this = this;

            const resize = (evt) => {
                document.body.style.cursor = "ew-resize";
                let f = evt.clientX;
                el.style.width = f + "px";
            };

            const handlemousedown = (evt) => {
                // console.log(evt);
                if (evt.offsetX < minSize) {
                    // m_pos = evt.x;
                    el.style.transition ='initial'; 
                    document.addEventListener("mousemove", resize, false);
                }
            };

            const handlemouseup = () => {
                el.style.transition ='';
                __this.width = el.style.width;
                document.body.style.cursor = "";
                document.removeEventListener("mousemove", resize, false);
            };
            // console.log(el.style);
            // el.style['max-height'] = "100hv";
            drawerBorder.addEventListener( "mousedown", handlemousedown, false );
            document.addEventListener( "mouseup", handlemouseup, false );
        },  
        // groupclick: function (s) {
        //     if(this.$route.path !== s) this.$router.push(s)
        // },
        setClassId: function (classid, retries) {
            retries = retries || 0;
            let userid = this.users && this.users.currentUser && this.users.currentUser._id;
            if(retries < 3 && (!userid || !this.enrollment || !this.enrollment.list)){
                // console.log("retrying for user or enrollment in .4 seconds", this.userid, !!(this.enrollment && this.enrollment.list))
                retries++
                return setTimeout(this.setClassId(classid, retries), 400);
            }
            if(classid && userid && this.courseid && this.enrollment && this.enrollment.list && this.enrollment.list[userid] && this.enrollment.list[userid][classid]){
                this.classid = classid;
            } else {
                this.classid = false;
            }
            // console.log('setClassId', this.classid)
        },

        // /**
        //  * set css class for materials in nav based on progress and whether the material is 'required'
        //  */
        // matProgressClass: function (matrecord, retries) {
        //     retries = retries || 0;
        //     let cssclass = '';
        //     if(!matrecord || !matrecord._id){ 
        //         return '';
        //     }
        //     let userid = this.users && this.users.currentUser && this.users.currentUser._id;
        //     if(!userid){ 
        //         if(retries < 3){
        //             retries++;
        //             return setTimeout(this.matProgressClass(matrecord, retries), 422);
        //         }
        //     }
        //     let progress = this.enrollment && this.enrollment.list && this.enrollment.list[userid] && this.enrollment.list[userid][this.classid] && this.enrollment.list[userid][this.classid].progress && this.enrollment.list[userid][this.classid].progress[this.lessonid] && this.enrollment.list[userid][this.classid].progress[this.lessonid].materials;
        //     if(!progress){ 
        //         if(retries < 3){
        //             retries++;
        //             return setTimeout(this.matProgressClass(matrecord, retries), 422);
        //         }
        //     }            

        //     // console.log('matProgressClass returning', matrecord._id, progress[matrecord._id]);
        //     if(progress && progress[matrecord._id]) cssclass += ' materialviewed';
        //     cssclass += (matrecord.required) ? ' materialrequired' : ' materialoptional';

        //     return cssclass;
        
        // },
        handleClick: function () {
            // console.log('Click!', this.$vuetify.breakpoint.width);
            if(this.$vuetify.breakpoint.width < 960) this.toggleaction();
        },
        logout: function () {
            this.$store.dispatch('users/logout')
                .then(() =>{
                    if(this.$route.path !== '/') this.$router.push('/');
                })
        },
        loc: function (str) {
            return (this.localize && this.localize.languages && this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        switchLanguage: function(lang) {
            this.$store.dispatch("users/switchLanguage", lang )
                .then((result) => {
                    console.log('Nav.switchLanguage', result);
                    this.$vuetify.rtl = !!(lang === 'ar');
                })
        },
        init: function (retries, opts) {
            if(initlock){ 
                // console.log('nav init locked'); 
                return;
            }
            // console.log("nav.init running", retries, opts);
            retries = retries || 0;
            opts = opts || {};
            initlock = true; // console.log('nav.initlock')

            let change = false, classchange = false;
            if(this.$route.params.courseid   !== this.courseid)   this.courseid = this.$route.params.courseid || false;     change = true;
            if(this.$route.params.lessonid   !== this.lessonid)   this.lessonid = this.$route.params.lessonid || false;     change = true;
            if(this.$route.params.classid    !== this.classid )   this.setClassId(this.$route.params.classid);              change = true; classchange = true;
            if(this.$route.params.materialid !== this.materialid) this.materialid = this.$route.params.materialid || false; change = true;

            if(!change && this.isInitialized && !opts.refresh ){ 
                initlock = false;  
                // console.log('nav.init unchanged, so unlock')
                return;
            }

            let userid = this.users && this.users.currentUser && this.users.currentUser._id;
            let progress = this.enrollment && this.enrollment.list && 
                this.enrollment.list[userid] && 
                this.enrollment.list[userid][this.classid] && 
                this.enrollment.list[userid][this.classid].progress
                //  && 
                // this.enrollment.list[userid][this.classid].progress[this.lessonid] && 
                // this.enrollment.list[userid][this.classid].progress[this.lessonid].materials;

            if(retries < 5 && (!userid || !progress || !this.lessons.materials)){
                retries++;
                initlock = false;  // 
                // console.log('nav.init unlock for retry')

                return setTimeout(this.init(retries), 333);                
            }

            if(!this.classid || this.classid === 'none'){
                let myclasses = _filter(this.enrollment.list[userid], {course: this.courseid});
                if(myclasses && myclasses.length === 1){
                    this.setClassId(myclasses[0].classid);
                }
            }

            if(this.courses && !this.courses.list){ 
                // console.log("Nav getting course list");
                setTimeout(()=>{ return this.courses && !this.courses.list && this.$store.dispatch("courses/getList"); }, 300);
            }
            
            if(this.siteconfig && this.siteconfig.showeachmaterial ){
                // console.log("showeachmaterial", this.siteconfig.showeachmaterial)
                if(this.lessons.materials){   
                    // console.log('building nav sublist')         
                    if(!this.materialsList) this.materialsList = {};
                    let courseId; // current courseid
                    for(let lid in this.lessons.materials){  // lid is lessonid
                        // if(!this.lessons.list[lid]) continue;
                        courseId = this.lessons.list[lid].course;
                        if(!this.materialsList[courseId]) this.materialsList[courseId] = {};
                        if(!this.materialsList[courseId][lid]) this.materialsList[courseId][lid] = {};

                        let matprogress = progress && progress[lid] && progress[lid].materials
                        // console.log("Ready to review matprogress", matprogress)
                            
                        for(let mid in this.lessons.materials[lid]){ // mid is materialid
                            if(!this.materialsList[courseId][lid][mid]) {
                                // this is static based on the material's properties, 
                                // so we only need to do it once for each record on mount
                                this.materialsList[courseId][lid][mid] = {
                                    title: this.lessons.materials[lid][mid].title,
                                    required: this.lessons.materials[lid][mid].required,
                                    viewed: (matprogress && matprogress[mid]) || false,
                                    key: this.lessons.materials[lid][mid]._id
                                };
                            }
                            // but this should run on changes
                            if(userid && matprogress && matprogress[mid]){ 
                                // console.log("Nav needs checkmark", courseId, lid, mid)
                                this.$set(this.materialsList[courseId][lid][mid], 'viewed', matprogress[mid]); 
                            } else if(this.materialsList[courseId][lid][mid].viewed){
                                // console.log("Nav no checkmark", courseId, lid, mid)
                                this.$delete(this.materialsList[courseId][lid][mid], 'viewed')
                            }
                        }
                        
                    }
                    // console.log('materialsList set', (this.materialsList[cid]));
                } else if(retries < 3) {
                    retries++;
                    initlock = false;  // 
                    // console.log('nav.init unlock for materialsList retry')

                    return setTimeout(this.init(retries), 333);                       
                }
            } else {
                // console.log("skipping materialsList set", this.siteconfig && this.siteconfig.showeachmaterial, this.lessons.materials);

            }
            if(this.courseid) this.courseactive[this.courseid] = true;
            if(this.lessonid) this.lessonactive[this.lessonid] = true;
            // this.lessonid

            initlock = false; // console.log('nav.init unlock')

            // this.findActiveItem();

        },
        findActiveItem () {
            return;
            // let item = document.getElementsByClassName('v-list-item--active v-list-group__header');
            // console.log('findActiveItem', item)
            // if(item && item.length && item.length === 1 && item[0].scrollIntoView) item[0].scrollIntoView();
        },        
            
    },
    mounted () {
        this.setBorderWidth();
        this.setResizeEvents();
        this.init();

        // this.courseid = this.$route.params && this.$route.params.courseid || false;
        // this.lessonid = this.$route.params && this.$route.params.lessonid || false;
        // this.materialid = this.$route.params && this.$route.params.materialid || false;
        // this.setClassId(this.$route.params.classid);
        // this.classid = this.$route.params && this.$route.params.classid || false;
    },

    watch: {
        '$route.params': {
            handler: function(s){
                // console.log('nav.watcher params triggered', s);
                this.init(null, {refresh: 1});
            },
            deep: true,
            immediate: false
        },
        enrollment: {
            handler: function(s){
                // console.log('nav.watcher enrollment triggered', s);
                this.init(null, {refresh: 1});
            },
            deep: true,
            immediate: false
        },
        'lessons.materials': {
            handler: function(s){
                // console.log('nav.watcher lessons.materials triggered', s);
                this.init(null, {refresh: 1});
            },
            deep: true,
            immediate: false
        },
        'users.lang': {
            handler: function(s){
                // console.log('nav.watcher users.lang triggered', s);
                this.init(null, {refresh: 1});
            },
            deep: true,
            immediate: false            
        }
        // '$route.params.courseid': {
        //     handler: function(s){
        //         console.log("nav.watcher triggered, courseid", s);
        //         if(s !== this.courseid) this.courseid = this.$route.params.courseid || false;
        //     },
        //     deep: true,
        //     immediate: false
        // },
        // '$route.params.lessonid': {
        //     handler: function(s){
        //         console.log("nav.watcher triggered, lessonid", s);
        //         if(s !== this.lessonid) this.lessonid = this.$route.params.lessonid || false;
        //     },
        //     deep: true,
        //     immediate: false
        // },
        // '$route.params.classid': {
        //     handler: function(s){
        //         console.log("nav.watcher triggered, classid", s);
        //         if(s !== this.classid) this.setClassId(this.$route.params.classid);
        //         // this.classid = this.$route.params.classid || false;
        //     },
        //     deep: true,
        //     immediate: false
        // },    
        // '$route.params.materialid': {
        //     handler: function(s){
        //         console.log("nav.watcher triggered, materialid", s);
        //         if(s !== this.materialid) this.materialid = this.$route.params.materialid || false;
        //     },
        //     deep: true,
        //     immediate: false
        // },        
    }
};
</script>
