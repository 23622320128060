<template>
    <v-container>
        <v-row>
            <v-col><h3>{{ loc("Comments") }}</h3></v-col>
        </v-row>
        <v-row v-if="parent && parent.question" class="my-8">
            <v-col v-html="parent.question" class="discussionquestion"></v-col>
            <v-col cols='12'><v-btn v-if="isPosting === false" @click="handleAction('newPost')">{{ loc('Post Answer') }}</v-btn></v-col>
        </v-row>
        <v-row v-if="isPosting !== false" class='discussionpostformrow my-8'>
            <v-col cols='12'>
                <h3>{{ loc('Post Response') }}</h3>
            </v-col>
            <v-col cols='12'>
                <v-textarea name="newpost" :label="loc('Response')" v-model="isPosting" ref="newpostfield"></v-textarea>
            </v-col>
            <v-col cols="12" v-if="classRecord.selfpaced">
                <v-switch v-model="isPrivate" :label="loc('Make Post Private')"></v-switch>
                <div class="help">{{ loc('Mark your post private if you do not want to share it with everybody else taking the course as an individual. This does not apply when you are taking the course with a group. Note that administrators can always see all posts.') }}</div>
                <div v-if="isPrivate">
                    <v-text-field :label="loc('Share access')" v-model="sharedStr" ></v-text-field>
                    <div class='help'>{{ loc('Enter a list of email addresses separated by commas.  Users with these addresses will be able to see your post.') }}</div>
                </div>
            </v-col>
            <v-col cols='3'>
                <v-btn color="primary" @click="handleAction('submitPost')">{{ loc('Submit') }}</v-btn> 
                <v-btn @click="handleAction('cancelPost')" class="ml-4" color="secondary" dark >{{ loc('Cancel') }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-4 discussioncard" cols='12' v-if="isWorking"><v-card-title>{{ loc('Working...') }}</v-card-title></v-card>

                <v-card v-for="(item, idx) in records" v-bind:key="idx" :class="'mb-4 discussioncard ' + ((item._id === commentId) ? 'discussionhighlight' : '')" :ref="'card-'+item._id" >
                    <v-card-title v-if="item.subject" >{{item.subject}}</v-card-title>
                    <v-card-text>
                        <h3 v-if="item.authorname" class="mb-4">
                            {{item.authorname}} 
                            <span v-if="isMe(item)">
                                <v-icon small class='ml-4' @click="isEditing=item; setFocus('bodyfield-'+item._id);">edit</v-icon>
                                <v-icon small class='ml-4' @click="handleAction('remove', item)">delete</v-icon>
                            </span> 
                        </h3>
                        <p v-if="item.showtimestamp">{{item.timestamp}}</p>
                        <div class='commenttext pb-6' v-if="isEditing && isEditing._id === item._id">
                            <v-row>
                                <v-textarea name="updatepost" :label="loc('Response')" v-model="isEditing.body" :ref="'bodyfield-'+item._id"></v-textarea>
                            </v-row>
                            <v-row>
                                <v-col cols="12" v-if="classRecord.selfpaced">
                                    <v-switch v-model="item.isPrivate" :label="loc('Make Post Private')"></v-switch>
                                    <div class="help">{{ loc('Mark your post private if you do not want to share it with everybody else taking the course as an individual. This does not apply when you are taking the course with a group. Note that administrators can always see all posts.') }}</div>
                                    <div v-if="item.isPrivate">
                                        <v-text-field label="Share access" v-model="item.sharedStr" ></v-text-field>
                                        <div class='help'>{{ loc('Enter a list of email addresses separated by commas.  Users with these addresses will be able to see your post.') }}</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>                        
                                <v-btn color="primary" @click="savePost(item, true);">{{ loc('Submit') }}</v-btn> 
                                <v-btn @click="isEditing = false" class="ml-4" color="secondary" dark >{{ loc('Cancel') }}</v-btn>
                            </v-row>
                        </div>
                        <p class='commenttext' v-else v-html="item.body"></p>
                        <p v-if="showLink === item._id">Link: <router-link :to="linkBasePath+'/'+item._id">{{linkBasePath}}/{{item._id}}</router-link></p>
                        <div v-if="item.replies && item.replies.length" class="discussionreplies pr-6">
                            <h3>{{ loc('Replies') }}</h3>
                            <v-list three-line>
                                <template v-for="(reply, idx) in item.replies">
                                    <v-list-item :key="reply._id">
                                        <v-list-item-content>
                                            <v-list-item-title >
                                                {{ reply.authorname}}  
                                                <span v-if="isMe(reply, 'reply')">
                                                    <v-icon small class='ml-4' @click="isEditingReply = reply; setFocus('replybodyfield-'+reply._id);">edit</v-icon>
                                                    <v-icon small class='ml-4' @click="removeReply(item, reply, idx)">delete</v-icon>
                                                </span> 
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="reply.showtimestamp" v-html="reply.timestamp"></v-list-item-subtitle>
                                            <div v-if="isEditingReply && isEditingReply._id === reply._id">
                                                <v-textarea name="updatereply" :label="loc('Reply')" v-model="isEditingReply.body" :ref="'replybodyfield-'+reply._id"></v-textarea>
                                                <v-btn color="primary" @click="saveReply(item, reply, idx);">{{ loc('Submit') }}</v-btn> 
                                                <v-btn @click="isEditingReply = false" class="ml-4" color="secondary" dark >{{ loc('Cancel') }}</v-btn>                                            
                                            </div>
                                            <v-list-item-subtitle v-else v-html="reply.body"></v-list-item-subtitle>
                                        </v-list-item-content>                                    
                                    </v-list-item>
                                </template>
                            </v-list>
                        </div>
                        <div class="mr-6 mt-4" v-if="isReplying === item._id && isWorkingReply">
                            {{ loc('Working...') }}
                        </div>
                        <div class="mr-6 mt-4" v-else-if="isReplying === item._id">
                            <v-textarea name="newreply" :label="loc('Response')" v-model="replyBody" :ref="'newreplybodyfield-'+item._id"></v-textarea>
                            <div>
                                <v-btn color="primary" @click="handleAction('submitReply', item)">{{ loc('Submit') }}</v-btn> 
                                <v-btn @click="isReplying = false; replyBody = false" class="ml-4" color="secondary" dark >{{ loc('Cancel') }}</v-btn>
                            </div>
                        </div>
                        <div class="mr-6 mt-4" v-else>
                            <v-btn small @click="isReplying = item._id; replyBody = ''; setFocus('newreplybodyfield-'+item._id);">{{ loc('Reply') }}</v-btn>
                            <v-btn small class="ml-4" @click="showLink = ((showLink === item._id) ? false : item._id)">{{ ((item._id === showLink) ? loc("Hide Link") : loc("Show Link")) }}</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-4" v-if="hasMore">
            <v-col cols="12">
                <v-btn v-if="showMore" @click="showMore = false">{{ loc("Show Less") }}</v-btn>
                <v-btn v-else  @click="showMore = true">{{ loc("Show More") }}</v-btn>
            </v-col>
        </v-row>        
    </v-container>
</template>

<script>
import { mapState } from "vuex";
// import ContentBlock from './Content_Block';
import { filterId, filterDateTime, validate } from '../lib/filter';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import _trim from 'lodash/trim';

var classretries = 0, parentretries = 0;

export default {
    name: "Comments",
    props: { // not currently used.
        parentid: { type: String, required: false, default: '' },
        classid: { type: String, required: false, default: '' },
        commentid: { type: String, required: false, default: '' },
    },
    data (){
        return {
            isLoading: true,    // boolean
            isWorking: false,   // boolean
            isPosting: '',   // text of post
            isPrivate: false,
            shared: [],
            sharedStr: "",
            isEditing: false,
            isReplying: false,  // id of post reply is for
            isEditingReply: false,
            replyBody: false,       // text of reply
            isWorkingReply: false,
            commentId: this.commentid || this.$route.params.commentid,
            lessonId: this.$route.params.lessonid,
            parentId: this.parentid || this.$route.params.materialid,    // material _id
            parent: false,                                               // material record
            courseId: this.$route.params.courseid,
            classId: this.classid || this.$route.params.classid,
            classRecord: false,
            authorId: false,
            showLink: false,
            showMore: false,
            // hasMore: false,
            // records: false,
            // initRetries: 0
        }
    },
    // components: { ContentBlock },
    components: {},
    computed: {
        ...mapState(["users", "localize", "comments", "classes"]),
        records: function(){
            if(!this.parentId || !this.comments.list) return [];
            let list = _filter(this.comments.list, { parent: this.parentId });
            for(let i in list){
                list[i].isPrivate = (list[i].access === "private");
                if(list[i].shared) list[i].sharedStr = list[i].shared.join('/');
                if(list[i].replies){
                    for(let r in list[i].replies){
                        list[i].replies[r].timestamp = filterDateTime(list[i].replies[r].timestamp);
                    }
                }
            }
            list = _orderBy(list, ['timestamp'], ['desc']);
            if(!this.showMore) list = list.slice(0,3);
            return list;
        },
        hasMore: function(){
            let list = _filter(this.comments.list, { parent: this.parentId });
            return !!(list.length > 3);
        },
        linkBasePath: function(){
            // '/lessons/'+courseId+'/'+lessonId+'/'+classId+'/'+materialId
            return `/lessons/${this.courseId}/${this.lessonId}/${this.classId}/${this.parentId}`;
        }
    },
    methods: {
        loadComments: function (opts) {
            console.log("Comments loadComments");
            let data = {
                parent: this.parentId,
                author: this.authorId,
                context: this.classId
            };

            this.$store.dispatch("comments/getList", data)
                .then((result) => {
                    console.log("comments/getList returned", result);
                    this.isLoading = false;
                    if(result && result.comments){ 
                        this.parent = result; 
                        this.lessonId = this.parent.lesson;
                    }
                    if(this.commentId) this.scrollTo()

                    // this.records = result.comments || result;
                })
        },
        loc: function (str) {
            // console.log("Localizing", this.users.lang, str, this.localize.languages[this.users.lang])
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleAction: function(action, params, opts) {
            console.log("comments handleAction", action, params, opts);
            switch(action){
                case 'newPost':
                    this.isPosting = "";
                    this.setFocus('newpostfield');
                    break;
                case 'cancelPost':
                    this.isPosting = false;
                    break;
                case 'remove':
                    this.$store.dispatch("comments/remove", params);
                    break;
                case 'submitPost':
                    this.isWorking = true;
                    this.savePost(params);
                    break;
                case 'submitReply':
                    this.isWorkingReply = true;
                    this.saveReply(params);
            }
        },
        savePost: function(item, update) {
            let record;
            if(!update){
                record = {
                    body: "" + this.isPosting,
                    parent: this.parentId,
                    context: this.classId
                };
                if(item) record = Object.assign(item, record);
                if(this.isPrivate){ 
                    record.access = 'private';
                    record.sharedStr = this.sharedStr || false;
                } else {
                    record.access = false;
                    record.sharedStr = false;
                    record.shared = false;
                }
            } else {
                record = item;
            }

            if(record.sharedStr && typeof record.sharedStr === 'string') record.shared = record.sharedStr.split(',');

            console.log("Posting", record, "\nisPrivate:",this.isPrivate);
            this.isPosting = false;
            this.$store.dispatch("comments/savePost", record)
                .then((result) => {
                    console.log("comments.savePost result", result);
                    this.isWorking = false;
                    this.isEditing = false;
                })
        },
        saveReply: function(item, reply, idx) {
            this.isWorkingReply = true;
            let replies = (item.replies && [...item.replies]) || [];
            let replyobj;
            if(reply && idx !== undefined){
                replies[idx] = reply;                
            } else {
                replyobj = {
                    author: this.users.currentUser.email,
                    authorname: this.users.currentUser.firstname + ' ' + this.users.currentUser.lastname,
                    body: this.replyBody,
                    context: this.classId,
                    lang: this.users.lang,
                    parent: this.isReplying,
                    timestamp: Date.now(),
                    _id: Date.now().toString(36)

                };
                replies.push(replyobj);
            } 
            let record = {
                _id: item._id,
                parent: this.parentId,
                context: this.classId,
                body: item.body,
                replies: replies
            }
            this.$store.dispatch("comments/savePost", record)
                .then((result) => {
                    console.log("comments.saveReply result", result);
                    this.isWorkingReply = false;
                    this.replyBody = false;
                    this.isReplying = false;
                    this.isEditingReply = false;
                })
        },
        removeReply: function(item, reply, idx){
            if(item && item.replies && item.replies[idx]) item.replies.splice(idx, 1);
            this.$store.dispatch("comments/savePost", item);
        },
        handleSharedChange: function(str){
            let list = str.split(",");
            let shared = [];
            for(let i in list){
                list[i] = _trim(list[i]);
                if(validate(list[i], {type: 'email'})) shared.push(list[i]);
            }
            this.shared = shared;
        },
        isMe: function(item, type){
            // if(type === 'reply') console.log('isMe?',type, item.author, this.users.currentUser._id, this.users.currentUser.email);
            return (item.author === this.users.currentUser._id || item.author ===  this.users.currentUser.email);
        },
        setFocus: function(el, t){
            if(this.$refs[el] && this.$refs[el].focus){
                this.$refs[el].focus();
            } else if(this.$refs[el] && this.$refs[el].length){
                this.$refs[el][0].focus();                
            } else if(t > 1000){
                console.log("Could not set focus for", el, this.$refs[el]);
            } else {
                t = t || 1;
                setTimeout(() => { this.setFocus(el, t * 10)}, t )
            }
        },
        scrollTo: function(el, t){
            el = el || 'card-'+this.commentId;
            if(this.$refs[el] && this.$refs[el].length){
                let pos, element = this.$refs[el][0] && this.$refs[el][0].$el;
                element && element.offsetTop && (pos = element.offsetTop - 30);
                pos && window.scrollTo(0, pos);
                console.log("Scrolled to array el", el, element, "at", pos)
                // this.$refs[el][0].focus();                
            } else if(this.$refs[el]){
                let pos, element = this.$refs[el];
                element && (pos = element.offsetTop);
                pos && window.scrollTo(0, pos);
                console.log("Scrolled to", el, element, "at", pos)
            } else if(t > 1000){
                console.log("Could not set focus for", el, this.$refs[el]);
            } else {
                t = t || 1;
                console.log("Retrying scrollTo", el, t)
                setTimeout(() => { this.setFocus(el, t * 10)}, t )
            }
        },
        init: function(path){
            console.log('comments.init', path);
            // path = path || this.$route.params.pathMatch;
            // if(!path && !path.length){ this.$router.push("/Courses"); return; }

            // if(path && typeof path === 'string'){
            //     let p = path.split('/');
            //     this.parentId = filterId(p[0]);
            //     this.classId = (p[1]) ? filterId(p[1]) : null;
            //     this.commentId = (p[2]) ? filterId(p[2]) : null;
            //     this.authorId = false;
            // } 
            if(!this.parentId){ 
                this.authorId = this.users.currentUser._id; 
                if(!this.authorId ){
                    if(parentretries < 3){
                        parentretries++;
                        setTimeout(() => { this.init(path) }, 300);
                    } else {
                        this.$router.push("/login");
                    }
                    return
                }
            }

            if(this.classId && (!this.classRecord || this.classId !== this.classRecord._id) ){
                this.classRecord = this.classes.list[this.classId];
                if(!this.classRecord){ 
                    if(classretries < 3){
                        classretries++;
                        console.log("Retrying class", this.classId);
                        setTimeout(() => { this.init(path); }, 300);
                        return;
                    }
                    this.classId = false; 
                }
            }

            if(this.classRecord) this.courseId = this.classRecord.course;

            console.log("init finishing", this.parentId, this.classId, this.authorId);
                // this.parentId = "hffmm8ww-hm2l65tz-hn3l6jyf";
            this.loadComments();
        }        
    },
    mounted () {
        console.log("Comments mounted");
    },
    created() {
        console.log("Comments", this.$route.params)
        this.init(this.$route.params.pathMatch);
    },
    watch: {
        '$route.params': {
            handler: function(s){
                console.log("lessons watcher triggered", s, this.$route);
                if(this.courseId !== this.$route.params.courseid) this.courseId = this.$route.params.courseid;
                if(this.lessonId !== this.$route.params.lessonid) this.courseId = this.$route.params.lessonid;
                if(this.classId !== this.$route.params.classid){ 
                    this.$store.dispatch("comments/clearAll")
                    this.classId = this.$route.params.classid; 
                }
                if(this.parentId !== this.$route.params.materialid) this.parentId = this.$route.params.materialid;
                if(this.commentId !== this.$route.params.commentid) this.commentId = this.$route.params.commentid;
                this.init()
                // this.init(this.$route.params.pathMatch);
                // this.context = s || (this.$route.params && this.$route.params.pathMatch);
            },
            deep: true,
            immediate: false
        }
    }
}
</script>
